<template>
    <div class="overview-filters" :class="{ 'is-opened': isFiltersVisible }">
        <div class="card overview-filters__card">
            <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
            <div class="app-container pt-0 pb-0">
                <b-collapse
                    animation="fade"
                    position="is-bottom"
                    :open.sync="isFiltersVisible"
                >
                    <div class="overview-filters__content card-content">
                        <div class="columns is-multiline">
                            <div class="column is-6">
                                <b-field label="Filter by time period">
                                    <div class="buttons">
                                        <b-button
                                            v-for="period in timePeriods"
                                            :key="`period${period.period}`"
                                            class="be-button is-wider"
                                            :class="{ 'is-primary': isPrimary(period.period) }"
                                            @click="selectTimePeriod(period.period)"
                                        >
                                            {{ period.label }}
                                        </b-button>
                                        <template v-if="allTimeEnabled">
                                            <b-button
                                                class="be-button is-wider"
                                                @click="selectAllTime()"
                                                :class="{ 'is-primary': allTime }"
                                            >
                                                All Time
                                            </b-button>
                                        </template>
                                        <template v-if="customDateEnabled">
                                            <b-button
                                                class="be-button is-wider is-primary"
                                            >
                                                Custom Time
                                            </b-button>
                                        </template>
                                    </div>
                                </b-field>
                                <!--
                                <b-field v-if="filterConfidence" label="Filter By Confidence Level">
                                    <filter-confidence v-model="confidenceLevel" />
                                </b-field>
                                -->
                                <b-field v-if="!allTime" label="Or select a date range">
                                    <div class="overview-filters__datetime">
                                        <div class="overview-filters__datetime__col is-start">

                                            <b-field label="From" custom-class="date-range-label">
                                                <b-datepicker
                                                    v-model="startDate"
                                                    v-bind="datepickerOptions"
                                                    placeholder="Click to select..."
                                                    icon="calendar-today"
                                                    type="is-secondary"
                                                />
                                                <b-clockpicker ref="clockpickerStart" v-model="startTime" placeholder="Click to select..." icon="clock" type="is-secondary">
                                                    <b-button
                                                        label="Confirm"
                                                        type="is-primary"
                                                        @click="updateTimeRange()"
                                                    />
                                                </b-clockpicker>
                                            </b-field>
                                        </div>
                                        <div class="overview-filters__datetime__col is-end">

                                            <b-field label="To" custom-class="date-range-label">
                                                <b-datepicker v-model="endDate" v-bind="datepickerOptions" placeholder="Click to select..." icon="calendar-today" type="is-primary" />
                                                <b-clockpicker ref="clockpickerEnd" v-model="endTime" placeholder="Click to select..." icon="clock" type="is-secondary">
                                                    <b-button
                                                        label="Confirm"
                                                        type="is-primary"
                                                        @click="updateTimeRange()"
                                                    />
                                                </b-clockpicker>
                                            </b-field>
                                        </div>
                                    </div>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-field v-if="filterRisk" label="Filter by Risk Score">
                                    <filter-risk v-model="riskLevel" />
                                </b-field>

                                <b-field label="Filter by tag">
                                    <filter-search
                                        type="tags"
                                        v-model="filterTags"
                                    />
                                </b-field>
                            </div>
                            <!--
                            <div class="column is-6 wide-column">
                            </div>
                            -->
                        </div>
                        <div class="overview-filters__buttons">
                            <div class="overview-filters__buttons__primary">
                                <div class="overview-filters__buttons__apply">
                                    <b-button
                                        class="be-button"
                                        type="is-primary"
                                        @click="applyFilters"
                                        :loading="isLoading"
                                    >
                                        Apply
                                    </b-button>
                                </div>
                                <div class="overview-filters__buttons__reset">
                                    <b-button
                                        :disabled="isLoading"
                                        class="be-button is-secondary"
                                        @click.prevent="resetFormState"
                                    >
                                        Reset
                                    </b-button>
                                </div>
                            </div>
                            <div
                                v-if="canExport"
                                class="overview-filters__buttons__export"
                                :active="showExportOptions"
                            >
                                <b-button
                                    class="be-button is-success export-button"
                                    @click="toggleExportOptions"
                                    :disabled="isLoading"
                                >
                                    Export
                                </b-button>
                                <b-button
                                    class="be-button"
                                    @click="exportCurrentPage"
                                    v-if="showExportOptions"
                                >
                                    Current Page
                                </b-button>
                                <b-button
                                    class="be-button"
                                    @click="exportNumberOfPages"
                                    v-if="showExportOptions"
                                >
                                    Number of Pages
                                </b-button>
                                <b-button
                                    class="be-button"
                                    @click="exportAllPages"
                                    v-if="showExportOptions"
                                >
                                    All Pages ( based on current filter)
                                </b-button>
                            </div>
                        </div>
                        <b-modal v-if="canExport" :active.sync="isExportProgressModalActive" class="overview-filters__progress" has-modal-card full-screen-background>
                            <section class="modal-card-body">
                                <h1 class="title">Export Progress</h1>
                                <b-progress
                                    type="is-success"
                                    :value="exportProgress.value"
                                    format="percent"
                                    :max="100"
                                    show-value
                                    size="is-large"
                                />
                            </section>
                            <div class="overview-filters__progress__background" />
                        </b-modal>
                    </div>
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-footer overview-filters__footer"
                        role="button"
                    >
                        <div class="card-footer-item overview-filters__cta">
                            <button
                                type="button"
                                class="button be-button is-primary is-rounded filter-toggle"
                            >
                                <b-icon icon="filter" />
                                <span>
                                    {{ props.open ? 'Hide Filters' : 'Show Filters' }}
                                </span>
                            </button>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <template v-if="allTimeEnabled">
            <overview-filters-all-time-loader
                :active.sync="displayAllTimeWarningPopup"
                :is-loading="isLoading"
                @load="loadingAllTime"
                @cancel="cancelLoading"
            />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import FilterSearch from '@/components/widgets/filters/FilterSearch';
// import FilterConfidence from '@/components/widgets/filters/FilterConfidence';
import FilterRisk from '@/components/widgets/filters/FilterRisk';
import OverviewFiltersAllTimeLoader from '@/components/global/OverviewFiltersAllTimeLoader';
import noticesMixin from '@/mixins/noticesMixin';
import { cancelAllRequests } from '@/utils/api-tokens';
import { getDatepickerOptions } from '@/utils/datepicker';
import exportHelpers from '@/utils/export-helpers';
import { detectionsTransformer } from '@/utils/transformers/detections';

export default {
    name: 'OverviewFilters',

    mixins: [noticesMixin],

    props: {
        allTimeEnabled: {
            type: Boolean,
            default: false,
        },
        filterConfidence: {
            type: Boolean,
            default: false,
        },
        filterRisk: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canExport: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        OverviewFiltersAllTimeLoader,
        FilterSearch,
        // FilterConfidence,
        FilterRisk,
    },

    mounted() {
        this.selectTimePeriod('7');
    },

    data() {
        return {
            customDateEnabled: true,
            displayAllTimeWarningPopup: false,
            datepickerOptions: getDatepickerOptions(),
            isExportProgressModalActive: false,
            showExportOptions: false,
            exportProgress: { value: 0 },
            maxItemPerPage: 100,
        };
    },

    computed: {
        ...mapState('dataFilters', ['timePeriods', 'allTime']),
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('dataExport', { getExportData: 'getData', getExportHeader: 'getHeader', getExportTitle: 'getTitle', getExportCurrentView: 'getCurrentView', getTotalPages: 'getTotalPages', getTotalItemCount: 'getTotalItemCount', getCSVLoadingStatus: 'getLoadingStatus' }),

        isPrimary() {
            return (periodValue) => this.periodRange === periodValue && !this.customDateEnabled;
        },

        getCurrentPage() {
            if (this.getExportCurrentView.includes('vehicle')) {
                return this.$store.state.explorer.detections.currentPage;
            }
            return this.$store.state.hs.explorer.detections.currentPage;
        },

        customDateRangeEnabled() {
            return this.allTimeEnabled && !this.allTime;
        },

        getPerPage() {
            if (this.getExportCurrentView.includes('vehicle')) {
                return this.$store.state.explorer.detections.perPage;
            }
            return this.$store.state.hs.explorer.detections.perPage;
        },

        isFiltersVisible: {
            set(val) {
                this.$store.commit('dashboard/layout/toggleOverviewFilters', val);
            },
            get() {
                return this.$store.state.dashboard.layout.overviewFiltersVisible;
            },
        },

        confidenceLevel: {
            set(confidenceLevel) {
                this.$store.commit('dataFilters/setConfidenceLevel', { confidenceLevel });
            },
            get() {
                return this.$store.state.dataFilters.confidenceLevel;
            },
        },

        riskLevel: {
            set(riskLevel) {
                this.$store.commit('dataFilters/setRiskLevel', { riskLevel });
            },
            get() {
                return this.$store.state.dataFilters.riskLevel;
            },
        },

        filterTags: {
            set(val) {
                this.$store.commit('dataFilters/setSelectedTags', { tags: val || [] });
            },
            get() {
                return this.$store.state.dataFilters.selectedTags;
            },
        },

        filterDetectionTypes: {
            set(val) {
                this.$store.commit('dataFilters/setSelectedDetectionTypes', { tags: val || [] });
            },
            get() {
                return this.$store.state.dataFilters.selectedDetectionTypes;
            },
        },

        startDate: {
            set(val) {
                this.customDateEnabled = true;
                this.$store.commit('dataFilters/setStartDate', { startDate: val });
            },
            get() {
                return this.$store.state.dataFilters.startDate;
            },
        },

        startTime: {
            set(val) {
                this.customDateEnabled = true;
                this.$store.commit('dataFilters/setStartTime', { hours: val.getHours(), minutes: val.getMinutes(), seconds: val.getSeconds() });
            },
            get() {
                return this.$store.state.dataFilters.startDate;
            },
        },

        endDate: {
            set(val) {
                this.customDateEnabled = true;
                this.$store.commit('dataFilters/setEndDate', { endDate: val });
            },
            get() {
                return this.$store.state.dataFilters.endDate;
            },
        },

        endTime: {
            set(val) {
                this.customDateEnabled = true;
                this.$store.commit('dataFilters/setEndTime', { hours: val.getHours(), minutes: val.getMinutes(), seconds: val.getSeconds() });
            },
            get() {
                return this.$store.state.dataFilters.endDate;
            },
        },

        periodRange: {
            set(period) {
                this.setDatesRange({
                    startDate: this.startDate,
                    endDate: this.endDate,
                    period,
                });
            },
            get() {
                return this.$store.state.dataFilters.period;
            },
        },

        pathPrefix() {
            let prefix = '';
            switch (this.getExportCurrentView) {
                case 'hs-safety':
                    prefix = 'hs/explorer/detections/zones/';
                    break;
                case 'hs-ppe':
                    prefix = 'hs/explorer/detections/ppe/';
                    break;
                case 'hs-violations':
                    prefix = 'hs/explorer/detections/';
                    break;
                case 'vehicle-detections':
                    prefix = 'explorer/detections/';
                    break;
                case 'vehicle-unique':
                    prefix = 'explorer/detections/unique/';
                    break;
                case 'vehicle-images':
                    prefix = 'explorer/images/';
                    break;
                default:
                    prefix = 'hs/explorer/images/';
            }
            return prefix;
        },

    },

    watch: {
        getDateRange: {
            handler() {
                this.onUpdate();
            },
            deep: true,
        },
        filterTags: {
            handler() {
                this.onUpdate();
            },
            deep: true,
        },
        filterDetectionTypes: {
            handler() {
                this.onUpdate();
            },
            deep: true,
        },
        confidenceLevel() {
            this.onUpdate();
        },
        riskLevel() {
            this.onUpdate();
        },
    },

    methods: {
        ...mapMutations('dataFilters', ['setRange', 'setPeriod', 'setAllTime', 'applyFilters', 'resetState']),
        ...mapMutations('dataExport', ['setLoadingStatus']),

        ...mapActions({
            loadData: 'maui/detections/map/loadData',
        }),

        resetFormState() {
            this.resetState();
            this.selectTimePeriod('7');
        },

        updateTimeRange() {
            this.customDateEnabled = true;

            this.setDatesRange({
                startDate: new Date(this.startDate),
                endDate: new Date(this.endDate),
                period: null,
            });

            this.$refs.clockpickerStart.close();
            this.$refs.clockpickerEnd.close();
        },

        setDatesRange({ startDate = null, endDate = null, period = null }) {
            if (this.validateRange({ startDate, endDate })) {
                this.setRange({
                    period,
                    startDate: startDate || this.startDate,
                    endDate: endDate || this.endDate,
                });
            }
        },

        validateRange({ startDate = null, endDate = null }) {
            if (startDate) {
                return true;
            }
            if (endDate) {
                return true;
            }
            return false;
        },

        selectTimePeriod(period) {
            const until = this.$date().add(1, 'minute');
            const from = period === 'today'
                ? until.startOf('day')
                : until.startOf('day').subtract(period - 1, 'day');

            this.setDatesRange({
                startDate: from.toDate(),
                endDate: until.toDate(),
                period,
            });

            switch (period) {
                case 'today':
                case '7':
                case '30':
                case '90':
                    this.customDateEnabled = false;
                    break;
                default:
                    this.customDateEnabled = true;
            }
        },

        onUpdate() {
            this.$emit('update', { isPaginated: false });
        },

        selectAllTime() {
            if (this.allTime) {
                return;
            }
            this.displayAllTimeWarningPopup = true;
        },

        loadingAllTime() {
            this.$emit('update', { isPaginated: true });
            this.loadData({ allTime: true })
                .then(() => {
                    this.displayAllTimeWarningPopup = false;
                    this.setAllTime({ allTime: true });
                    this.setPeriod({
                        period: null,
                    });
                });
        },

        cancelLoading() {
            cancelAllRequests({ refresh: true });
            this.displayAllTimeWarningPopup = false;
            this.setAllTime({ allTime: false });
        },

        toggleExportOptions() {
            this.showExportOptions = !this.showExportOptions;
        },

        exportCurrentPage() {
            try {
                this.exportProgress.value = 0;
                const rowData = this.getExportData;

                let outputCsv = this.getExportHeader;

                rowData.forEach((row) => {
                    outputCsv += row.join(',');
                    outputCsv += '\n';
                });

                this.exportProgress.value = 50;

                const blob = new Blob([outputCsv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = this.getExportTitle;
                a.click();

                this.exportProgress.value = 100;

                this.displaySuccessNotice({
                    message: 'Pages were exported successfully',
                    indefinite: true,
                });
            } catch (error) {
                console.log(error);
                this.displayErrorNotice({ message: 'Export failed' });
            }
        },

        exportNumberOfPages() {
            const pageSize = this.getPerPage;
            const maxPages = parseInt(Math.floor(1000 / pageSize), 10);
            this.$buefy.dialog.prompt({
                message: 'Number of pages to export from the current page:',
                inputAttrs: {
                    type: 'number',
                    min: 1,
                    max: maxPages,
                },
                confirmText: 'Export',
                cancelText: 'Cancel',
                canCancel: ['outside', 'escape', 'button'],
                onConfirm: (numPagesToExport) => {
                    try {
                        this.exportMultiplePages(numPagesToExport);
                    } catch (error) {
                        this.displayErrorNotice({ message: 'Failed to export multiple pages' });
                    }
                },
                onCancel: () => {
                    this.displayErrorNotice({ message: 'Export canceled' });
                },
            });
        },

        async exportMultiplePages(numPagesToExport) {
            await this.applyFilters();

            const pageSize = this.getPerPage;
            const numPages = parseInt(numPagesToExport, 10);

            if (Number.isNaN(numPages)) {
                this.$buefy.toast.open({
                    message: 'Please enter a valid number of pages to export.',
                    type: 'is-danger',
                });
                return;
            }

            this.exportProgress.value = 0;
            this.isExportProgressModalActive = true;
            const exportIncrement = 99 / numPages;

            const startPage = this.getCurrentPage;

            // Cursor starts at page 0 so we will need to calculate how many entries we need to skip to get to the start of the page.
            const initialLimit = startPage * pageSize;

            const results = await this.$store.dispatch(
                `${this.pathPrefix}exportDataUsingCursor`,
                { limit: initialLimit },
            );
            let { next } = results;

            const rowData = [];
            try {
                for (let i = 0; i < numPages; i++) {
                    if (!next) {
                        break;
                    }

                    /* eslint-disable-next-line no-await-in-loop */
                    const data = await this.$store.dispatch(
                        `${this.pathPrefix}exportDataUsingCursor`,
                        { nextCursor: next, limit: pageSize },
                    );
                    if (!data) {
                        throw new Error('Error while exporting data');
                    }

                    ({ next } = data);

                    const pageDataObjbs = detectionsTransformer(data?.results);
                    const formattedData = exportHelpers.formatVehicleData(
                        pageDataObjbs,
                        { rootState: this.$store.state },
                    );

                    rowData.push(...formattedData);
                    this.exportProgress.value += exportIncrement;
                }
            } catch (error) {
                console.log(error);
                this.displayErrorNotice({ message: 'Export failed' });
                this.isExportProgressModalActive = false;
                return;
            }

            try {
                let outputCsv = this.getExportHeader;
                rowData.forEach((row) => {
                    outputCsv += row.join(',');
                    outputCsv += '\n';
                });

                const blob = new Blob([outputCsv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = this.getExportTitle;
                a.click();
                this.exportProgress.value = 100;

                this.displaySuccessNotice({
                    message: 'Pages were exported successfully',
                    indefinite: true,
                });
            } catch (error) {
                console.error('Error exporting pages:', error);
            }
            this.isExportProgressModalActive = false;
        },

        async exportAllPages() {
            await this.applyFilters();
            this.isExportProgressModalActive = true;

            const limit = 100;
            const totalPages = (this.getTotalPages * this.getPerPage) / limit;
            const exportIncrement = 99 / totalPages;
            this.exportProgress.value = 0;

            const rowData = [];
            let next = null;

            do {
                try {
                    /* eslint-disable no-await-in-loop */
                    const data = await this.$store.dispatch(
                        `${this.pathPrefix}exportDataUsingCursor`,
                        { nextCursor: next, limit },
                    );
                    if (!data) {
                        throw new Error('Error while exporting data');
                    }
                    ({ next } = data);

                    this.setLoadingStatus(true);

                    const pageDataObjbs = detectionsTransformer(data?.results);

                    const formattedData = exportHelpers.formatVehicleData(
                        pageDataObjbs,
                        { rootState: this.$store.state },
                    );

                    rowData.push(...formattedData);

                    this.exportProgress.value = Math.min(this.exportProgress.value + exportIncrement, 99);
                } catch (error) {
                    this.displayErrorNotice({ message: 'Export failed' });
                    this.isExportProgressModalActive = false;
                    return;
                }
            } while (next != null);

            try {
                let outputCsv = this.getExportHeader;
                rowData.forEach((row) => {
                    outputCsv += row.join(',');
                    outputCsv += '\n';
                });

                const blob = new Blob([outputCsv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = this.getExportTitle;
                a.click();
                this.exportProgress.value = 100;

                this.displaySuccessNotice({
                    message: 'Pages were exported successfully',
                    indefinite: true,
                });
            } catch (error) {
                console.error('Error exporting pages:', error);
            }
            this.isExportProgressModalActive = false;
        },

        exportDataAsCSV(data) {
            let csv = this.getExportHeader;
            data.forEach((row) => {
                csv += row.join(',');
                csv += '\n';
            });
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            anchor.download = this.getExportTitle;
            anchor.click();

            this.exportProgress.value = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-filters {
    background: $grey-dark;

    &__content {
        position: relative;
        padding-left: 0;
        padding-right: 0;
    }

    &__heading {
        color: $grey;
        font-weight: $weight-normal;
        font-size: 14px;
        margin-bottom: 1em;
    }

    &__filters {
        position: relative;
        padding-bottom: 17px;
        margin-bottom: 16px;

        &:after {
            content: '';
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0;
            height: 1px;
            background: $grey;
            opacity: 0.25;
        }
    }

    &__cta {
        text-align: center;
        margin: 0;
        cursor: pointer;
        display: block;
        width: 100%;
        color: $primary;
        font-size: 13px;
        display: inline-flex;
        align-items: center;

        .icon {
            margin-right: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__datetime {
        &__col {
            display: flex;
            align-items: stretch;

            .field {
                margin-top: 0.5em !important;
            }
        }

        .field {
            margin: 0;
        }

        .field + .field {
            margin-left: 0.5em;
        }

        &__separator {
            width: 1.5em;
            display: flex;
            align-items: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;

            span {
                width: 100%;
                display: block;
                height: 1px;
                background: #aaa;
            }
        }
    }

    &__footer {
        border-top-color: transparent;
    }

    &.is-opened & {
        &__footer {
            border-top-color: $border;
        }
    }

    ::v-deep .datepicker-footer {
        background: transparent;
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .button {
            text-align: center;
            margin-right: 5px;
        }

        &__export {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        &__primary {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }
    }

    &__progress {
        &__background {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(25, 29, 35, 0.8);
            z-index: -999;
        }
        ::v-deep .modal-background {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    .wide-column {
        width: 80%;
    }

    .filter-toggle {
        font-weight: unset;
        height: 30px;
    }

    .export-button {
        border: 1px solid $grey-darker;
        background-color: rgba(0, 0, 0, 0);
        color: $primary;
    }

    ::v-deep .b-clockpicker-btn {
        color: $secondary;
        background-color: rgba(255, 0, 0, 0);

        &.active {
            background-color: rgba(255,255,255,0.2);
        }
    }
}

::v-deep .b-datepicker {
    .datepicker-cell {
        background-color: green;
    }
}

::v-deep .datepicker {
    .datepicker-table {
        .datepicker-cell {
            &.is-selected{
                color: $primary;
                background-color: $secondary;
            }
        }

        .is-today {
            border: 1px solid rgba($secondary, 0.5);
        }
    }
}

// .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today
</style>
