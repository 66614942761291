import Vue from 'vue';
import axios from 'axios';
import { find, findIndex, isString } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { DEFAULT_SITES_PAGINATION_PER_PAGE } from '@/utils/sites/constants';

const getDefaultState = () => ({
    // index sites information
    sites: [],
    sitesCurrentPage: 1,
    sitesPerPage: DEFAULT_SITES_PAGINATION_PER_PAGE,
    sitesCount: 0,

    // full sites information
    sitesFull: [],
    // sites cameras
    sitesCameras: [],

    // only cameras endpoints { id: endpoint
    camerasEndpoints: {},

    status: '',
    siteStatus: '',
    camerasStatus: '',
    editStatus: '',

    currentPage: 1,

    //
    camerasCurrentPage: 1,
    camerasPerPage: DEFAULT_SITES_PAGINATION_PER_PAGE,
    camerasCount: 0,

    frequencyList: [],

    mapView: 'default',
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
    isError: (state) => state.status === STATUS_ERROR,

    isSiteLoading: (state) => state.siteStatus === STATUS_LOADING,
    isSiteSuccess: (state) => state.siteStatus === STATUS_SUCCESS,
    isSiteError: (state) => state.siteStatus === STATUS_ERROR,

    isCamerasLoading: (state) => state.camerasStatus === STATUS_LOADING,
    isCamerasSuccess: (state) => state.camerasStatus === STATUS_SUCCESS,
    isCamerasError: (state) => state.camerasStatus === STATUS_ERROR,

    isEditLoading: (state) => state.editStatus === STATUS_LOADING,
    isEditSuccess: (state) => state.editStatus === STATUS_SUCCESS,
    isEditError: (state) => state.editStatus === STATUS_ERROR,
};

const mutations = {
    sitesStatus(state, status) {
        state.status = status;
    },

    siteStatus(state, status) {
        state.siteStatus = status;
    },

    camerasStatus(state, status) {
        state.camerasStatus = status;
    },

    editStatus(state, status) {
        state.editStatus = status;
    },

    sitesSuccess(state, data) {
        state.status = STATUS_SUCCESS;
        if (data.results) {
            state.sites = data.results;
        }
        if (data.count) {
            state.sitesCount = data.count;
        }
    },

    setSitesPage(state, { page }) {
        state.sitesCurrentPage = page;
    },

    setSitesPerPage(state, { perPage }) {
        state.sitesPerPage = perPage;
    },

    setCamerasPage(state, { page }) {
        state.camerasCurrentPage = page;
    },

    setCamerasPerPage(state, { perPage }) {
        state.camerasPerPage = perPage;
    },

    siteSuccess(state, { siteId, site }) {
        state.siteStatus = STATUS_SUCCESS;
        const existingIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingIndex > -1) {
            state.sitesFull[existingIndex] = site;
        } else {
            state.sitesFull = [...state.sitesFull, site];
        }
    },

    siteTagsSuccess(state, { siteId, tags }) {
        state.status = STATUS_SUCCESS;
        const existingIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingIndex < 0) {
            return;
        }

        const sites = [...state.sitesFull];
        sites[existingIndex] = {
            ...state.sitesFull[existingIndex],
            tags,
        };

        state.sitesFull = sites;
    },

    siteCreatedSuccess(state, { data }) {
        state.editStatus = STATUS_SUCCESS;
        state.sites = [
            ...state.sites,
            data,
        ];
        state.sitesFull = [
            ...state.sitesFull,
            {
                cameras: [],
                ...data,
            },
        ];
    },

    siteUpdatedSuccess(state, { siteId, data }) {
        state.editStatus = STATUS_SUCCESS;

        const existingSiteIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingSiteIndex > -1) {
            Vue.set(state.sitesFull, existingSiteIndex, data);
        }

        const existingSitesIndex = findIndex(state.sites, { id: siteId });
        if (existingSitesIndex > -1) {
            Vue.set(state.sites, existingSitesIndex, data);
        }
    },

    siteRemovedSuccess(state, { siteId }) {
        state.editStatus = STATUS_SUCCESS;

        const existingSiteIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingSiteIndex > -1) {
            Vue.delete(state.sitesFull, existingSiteIndex);
        }

        const existingSitesIndex = findIndex(state.sites, { id: siteId });
        if (existingSitesIndex > -1) {
            Vue.delete(state.sites, existingSitesIndex);
        }

        const existingSiteCameras = findIndex(state.sitesCameras, { id: siteId });
        if (existingSiteCameras > -1) {
            Vue.delete(state.sitesCameras, existingSiteCameras);
        }
    },

    camerasSuccess(state, { siteId, cameras, count }) {
        state.camerasStatus = STATUS_SUCCESS;
        const existingIndex = findIndex(state.sitesCameras, { id: siteId });

        const sitesCameras = [...state.sitesCameras];

        const camerasObject = { id: siteId, cameras };
        if (existingIndex > -1) {
            sitesCameras[existingIndex] = camerasObject;
        } else {
            sitesCameras.push(camerasObject);
        }
        state.sitesCameras = sitesCameras;

        if (count) {
            state.camerasCount = count;
        }
    },

    cameraRemovedSuccess(state, { siteId, cameraId }) {
        state.editStatus = STATUS_SUCCESS;
        const existingCamerasIndex = findIndex(state.sitesCameras, { id: siteId });
        if (existingCamerasIndex === -1) {
            return;
        }

        const cameras = state.sitesCameras[existingCamerasIndex];
        const existingCameraIndex = findIndex(cameras.cameras, { id: cameraId });
        cameras.cameras.splice(existingCameraIndex, 1);

        Vue.set(state.sitesCameras, existingCamerasIndex, cameras);
    },

    cameraCreatedSuccess(state, { siteId, data }) {
        state.editStatus = STATUS_SUCCESS;
        const existingCamerasIndex = findIndex(state.sitesCameras, { id: siteId });
        if (existingCamerasIndex === -1) {
            return;
        }

        const cameras = state.sitesCameras[existingCamerasIndex];
        cameras.cameras.push(data);
        Vue.set(state.sitesCameras, existingCamerasIndex, cameras);
    },

    cameraUpdatedSuccess(state, { siteId, cameraId, data }) {
        state.editStatus = STATUS_SUCCESS;

        const existingCamerasIndex = findIndex(state.sitesCameras, { id: siteId });
        if (existingCamerasIndex === -1) {
            return;
        }

        const cameras = state.sitesCameras[existingCamerasIndex];
        const existingCameraIndex = findIndex(cameras.cameras, { id: cameraId });

        cameras.cameras[existingCameraIndex] = {
            ...cameras.cameras[existingCameraIndex],
            ...data,
        };

        Vue.set(state.sitesCameras, existingCamerasIndex, cameras);
    },

    cameraTagsSuccess(state, { siteId, cameraId, tags }) {
        state.editStatus = STATUS_SUCCESS;
        const existingCamerasIndex = findIndex(state.sitesCameras, { id: siteId });
        if (existingCamerasIndex === -1) {
            return;
        }

        const cameras = state.sitesCameras[existingCamerasIndex];
        const existingCameraIndex = findIndex(cameras.cameras, { id: cameraId });
        if (existingCameraIndex === -1) {
            return;
        }

        cameras.cameras[existingCameraIndex].tags = tags;

        state.sitesCameras = [
            ...state.sitesCameras,
            { ...cameras },
        ];
    },

    cameraEndpointSuccess(state, { cameraId, endpoint }) {
        Vue.set(
            state.camerasEndpoints,
            cameraId,
            endpoint,
        );
    },

    setFrequencyList(state, { frequencyList }) {
        state.frequencyList = frequencyList;
    },

    setMapView(state, { mapView }) {
        state.mapView = mapView;
    },

    siteFileUploaded(state, { siteId, document }) {
        const existingSiteIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingSiteIndex > -1) {
            Vue.set(state.sitesFull, existingSiteIndex, {
                ...state.sitesFull[existingSiteIndex],
                document,
            });
        }
    },

    siteFileDeleted(state, { siteId }) {
        const existingSiteIndex = findIndex(state.sitesFull, { id: siteId });
        if (existingSiteIndex > -1) {
            Vue.set(state.sitesFull, existingSiteIndex, {
                ...state.sitesFull[existingSiteIndex],
                document: null,
            });
        }
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    getSites({ commit, state }, { name = '', force = false }) {
        if (!force && state.sites.length) {
            return Promise.resolve(true);
        }
        commit('sitesStatus', STATUS_LOADING);

        return axios({
            url: getApiUrl({ path: 'sites' }),
            params: {
                name,
                page: state.sitesCurrentPage,
                page_length: state.sitesPerPage,
            },
        })
            .then((response) => {
                commit('sitesSuccess', response.data);
            })
            .catch((err) => {
                commit('sitesStatus', STATUS_ERROR);
                errorHandler(err);
            });
    },

    getSite({ dispatch }, { siteId, force = false, includeCameras = true }) {
        const promises = [dispatch('getSiteInfo', { siteId, force })];
        if (includeCameras) {
            promises.push(dispatch('getSiteCameras', { siteId, force }));
        }

        return Promise.all(promises);
    },

    getSiteInfo({ commit, state }, { siteId, force = false }) {
        if (!force) {
            const existing = find(state.sitesFull, { id: siteId });
            if (existing) {
                return Promise.resolve(true);
            }
        }

        commit('siteStatus', STATUS_LOADING);
        return axios({
            url: getApiUrl({ path: `sites/${siteId}` }),
        })
            .then((response) => {
                commit('siteSuccess', { siteId, site: response.data });
            })
            .catch((err) => {
                commit('siteStatus', STATUS_ERROR);
                errorHandler(err);
                // throw another error to display a message on the frontend
                throw new Error('Site not found');
            });
    },

    getSiteCameras({ commit, state }, { siteId, force = false }) {
        if (!force) {
            const existing = find(state.sitesCameras, { id: siteId });
            if (existing) {
                return Promise.resolve(true);
            }
        }

        commit('camerasStatus', STATUS_LOADING);
        return axios({
            url: getApiUrl({ path: `sites/${siteId}/cameras` }),
            params: {
                page: state.camerasCurrentPage,
                page_length: state.camerasPerPage,
            },
        })
            .then((response) => {
                // @TODO add pagination
                commit('camerasSuccess', { siteId, cameras: response.data.results, count: response.data.count });
            }).catch((err) => {
                commit('camerasStatus', STATUS_ERROR);
                errorHandler(err);
                throw new Error('Cannot load site cameras');
            });
    },

    updateSiteTags({ commit }, { siteId, tags = [] }) {
        commit('editStatus', STATUS_LOADING);
        return axios({
            method: 'POST',
            url: getApiUrl({ path: `tags/site/${siteId}` }),
            data: {
                tags: tags.map((tag) => tag.slug),
            },
        })
            .then((response) => {
                const tags = response && response.data && response.data.tags ? response.data.tags : [];
                commit('siteTagsSuccess', { siteId, tags });
            })
            .catch((err) => {
                commit('editStatus', '');
                errorHandler(err);
                throw new Error('Cannot update site tags');
            });
    },

    updateSite({ commit }, { siteId, data }) {
        commit('editStatus', STATUS_LOADING);

        return axios({
            method: 'PUT',
            url: getApiUrl({ path: `sites/${siteId}` }),
            data,
        })
            .then((response) => {
                commit('siteUpdatedSuccess', { siteId, data: response.data });
            })
            .catch((err) => {
                commit('editStatus', '');
                // validation error
                if (err.response
                    && err.response.data
                    && err.response.data.non_field_errors
                    && err.response.data.non_field_errors.length) {
                    throw new Error(err.response.data.non_field_errors[0]);
                }
                errorHandler(err);
                throw new Error('Cannot update site');
            });
    },

    createSite({ commit }, { data }) {
        commit('editStatus', STATUS_LOADING);
        return axios({
            method: 'POST',
            url: getApiUrl({ path: 'sites' }),
            data,
        })
            .then((response) => {
                commit('siteCreatedSuccess', { data: response.data });
                return { site: response.data };
            })
            .catch((err) => {
                commit('editStatus', '');
                // validation error
                if (err.response
                    && err.response.data
                    && err.response.data.non_field_errors
                    && err.response.data.non_field_errors.length) {
                    throw new Error(err.response.data.non_field_errors[0]);
                }
                errorHandler(err);
                throw new Error('Cannot create site');
            });
    },

    removeSite({ commit }, { siteId }) {
        commit('editStatus', STATUS_LOADING);

        return axios({
            method: 'DELETE',
            url: getApiUrl({ path: `sites/${siteId}` }),
        })
            .then(() => {
                commit('siteRemovedSuccess', { siteId });
            })
            .catch((err) => {
                commit('editStatus', '');
                errorHandler(err);
                throw new Error('Cannot remove site');
            });
    },

    removeSiteCamera({ commit }, { siteId, cameraId }) {
        commit('editStatus', STATUS_LOADING);
        return axios({
            method: 'DELETE',
            url: getApiUrl({ path: `cameras/${cameraId}` }),
        })
            .then(() => {
                commit('cameraRemovedSuccess', { siteId, cameraId });
            })
            .catch((err) => {
                commit('editStatus', '');
                errorHandler(err);
                throw new Error('Cannot remove site camera');
            });
    },

    createSiteCamera({ commit }, { siteId, data }) {
        commit('editStatus', STATUS_LOADING);
        return axios({
            method: 'POST',
            url: getApiUrl({ path: 'cameras' }),
            data: {
                ...data,
                name: `CAM: ${data.name}`,
                site: siteId,
            },
        })
            .then((response) => {
                commit('cameraCreatedSuccess', { siteId, data: response.data });
                return { camera: response.data };
            })
            .catch((err) => {
                commit('editStatus', '');
                // validation error
                if (
                    err.response
                    && err.response.data
                    && err.response.data.non_field_errors
                    && err.response.data.non_field_errors.length
                ) {
                    throw new Error(err.response.data.non_field_errors[0]);
                }
                errorHandler(err);
                throw new Error('Cannot create site camera');
            });
    },

    updateSiteCamera({ commit }, { siteId, cameraId, data }) {
        commit('editStatus', STATUS_LOADING);
        return axios({
            method: 'PUT',
            url: getApiUrl({ path: `cameras/${cameraId}` }),
            data: {
                ...data,
                site: siteId,
            },
        })
            .then((response) => {
                commit('cameraUpdatedSuccess', { siteId, cameraId, data: response.data });
            })
            .catch((err) => {
                commit('editStatus', '');
                // validation error
                if (err.response
                    && err.response.data
                    && err.response.data.non_field_errors
                    && err.response.data.non_field_errors.length) {
                    throw new Error(err.response.data.non_field_errors[0]);
                }
                errorHandler(err);
                throw new Error('Cannot update site camera');
            });
    },

    updateCameraTags({ commit }, { siteId, cameraId, tags = [] }) {
        commit('editStatus', STATUS_LOADING);

        const tagSlugs = tags.map((tag) => (
            isString(tag) ? tag : tag.slug
        ));

        return axios({
            method: 'POST',
            url: getApiUrl({ path: `tags/camera/${cameraId}` }),
            data: { tags: tagSlugs },
        })
            .then((response) => {
                const tags = response && response.data && response.data.tags ? response.data.tags : [];
                commit('cameraTagsSuccess', { siteId, cameraId, tags });
            })
            .catch((err) => {
                commit('editStatus', '');
                errorHandler(err);
                throw new Error('Cannot update camera tags');
            });
    },

    loadFrequencyList({ commit, state }) {
        if (state.frequencyList.length) {
            return Promise.resolve(true);
        }
        return axios({
            url: getApiUrl({ path: 'cameras/frequencylist' }),
        })
            .then((response) => {
                commit('setFrequencyList', { frequencyList: response.data });
            })
            .catch((err) => errorHandler('Cannot load frequency list', err));
    },

    loadCameraEndpoint({ commit, state }, { cameraId }) {
        if (state.camerasEndpoints[cameraId]) {
            return Promise.resolve(true);
        }

        return axios({
            url: getApiUrl({ path: `cameras/${cameraId}/endpoint` }),
        })
            .then((response) => {
                commit('cameraEndpointSuccess', {
                    cameraId,
                    endpoint: response.data.endpoint,
                });
            })
            .catch((err) => errorHandler('Cannot load camera endpoint', err));
    },

    // eslint-disable-next-line no-empty-pattern
    uploadSiteFile({ commit }, { siteId, file }) {
        const formData = new FormData();
        formData.append('document', file);

        return axios({
            method: 'PUT',
            url: getApiUrl({ path: `sites/document/${siteId}` }),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        })
            .then((response) => {
                commit('siteFileUploaded', { siteId, document: response.data.document });
            })
            .catch((err) => errorHandler('Cannot upload file', err));
    },

    // eslint-disable-next-line no-empty-pattern
    deleteSiteFile({ commit }, { siteId }) {
        return axios({
            method: 'DELETE',
            url: getApiUrl({ path: `sites/document/${siteId}` }),
        })
            .then(() => {
                commit('siteFileDeleted', { siteId });
            })
            .catch((err) => errorHandler('Cannot delete file', err));
    },

    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
