import { isString, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { isPlainObj } from 'vuescroll/src/shared/util';
import axios from 'axios';
import MediaImagePopup from '@/components/local/data/MediaImagePopup';
import { getApiUrl } from '@/utils/api';
import { PROXIMITY_DISTANCE_THRESHOLD } from '@/utils/constants';

const getPlatesAndImageAndWithDetailDefaults = () => ({
    plates: [],
    image: '',
    withDetail: false,
    detections: null,
    camera: null,
});

export default {
    components: {
        MediaImagePopup,
    },

    data() {
        return {
            isRowLoading: false,
            lastSelectedRow: null,
            lastSelectedRowIndex: null,
            platesAndImageAndWithDetail: getPlatesAndImageAndWithDetailDefaults(),
            showCarJamInfo: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoadingFull: 'media/images/isLoading',
        }),

        formattedApiResults() {
            return this.$store.getters[`${this.storePrefix}formattedApiResults`];
        },
    },

    methods: {
        ...mapActions({
            loadDataRow: 'media/images/loadDataRow',
            loadVideoDataRow: 'media/videos/loadDataRow',
        }),

        async loadCameraData(cameraId) {
            try {
                const result = await axios({
                    method: 'GET',
                    url: getApiUrl({ path: `cameras/lookup/${cameraId}` }),
                });
                return get(result, 'data', {});
            } catch (error) {
                console.error('Error loading camera data:', error);
                return {};
            }
        },

        videoMapper({ video, camera, row = null, image = null }) {
            const { rois } = camera;
            const violatorTrack = video.safety_distance_data.length > 0
                ? video.safety_distance_data[0].violator_track : [];

            const { violation_data: violationData = [] } = video;

            const speedDataLookup = video.speed_data.flatMap((row) => row.track.bboxes.map((item, index) => ({
                bb: {
                    l: Math.floor(item[0]),
                    t: Math.floor(item[1]),
                    r: Math.floor(item[2]),
                    b: Math.floor(item[3]),
                },
                frame_number: row.track.frame_no[index],
                confidence: null,
                rois: [],
                tags: [],
                object_class: row.track.object_class,
                plate: row.track.plate,
                speed: Math.round(row.track.speeds_by_frame[index]),
                trackerId: row.track.track_id,
            }))).reduce((acc, entry) => {
                const key = `${entry.frame_number}-${entry.trackerId}`;
                acc[key] = entry;
                return acc;
            }, {});

            const mappedTrackData = Object.entries(video.detection_data_tracks).map(
                ([trackerId, tracks]) => tracks.map((track) => {
                    const key = `${track.frame_number}-${trackerId}`;
                    const foundSpeedData = speedDataLookup[key] || {};
                    return { ...track, ...foundSpeedData };
                }),
            );

            const ppeRois = rois.find((roi) => roi.roi_type === 1);
            const detectionFrameMap = new Map();
            Object.values(video.detection_data_tracks).forEach(
                (tracks) => tracks.forEach((track) => {
                    const { frame_number: frameNum, object_class: objectClass, tags } = track;
                    const detectionFrame = detectionFrameMap.get(frameNum) || null;

                    let isPpeViolation = false;
                    if (ppeRois) {
                        if (objectClass.toLowerCase() === 'person' && track.rois.includes(ppeRois.id)) {
                            if (tags.length === 0) {
                                isPpeViolation = true;
                            }
                        }
                    }

                    if (detectionFrame) {
                        const {
                            count,
                            maxRisk,
                            isPpeViolation: isPpeViolationFromFrame = false,
                            ...rest
                        } = detectionFrame;

                        detectionFrameMap.set(frameNum, {
                            ...rest,
                            isPpeViolation: isPpeViolationFromFrame || isPpeViolation,
                            count: count + 1,
                            maxRisk: Math.max(maxRisk, track.risk_level),
                        });
                    } else {
                        detectionFrameMap.set(frameNum, {
                            count: 1,
                            maxRisk: track.risk_level,
                            isPpeViolation,
                        });
                    }
                }),
            );

            violatorTrack.forEach((track) => {
                const { frame_number: frameNum, classified_distances: distanceObj } = track;
                const frameData = detectionFrameMap.get(frameNum) || null;

                if (!frameData) {
                    return;
                }

                // We are filtering and then mapping because no point in mapping data we don't need.
                const distanceObjs = distanceObj
                    .filter((distanceObj) => distanceObj.distance < PROXIMITY_DISTANCE_THRESHOLD)
                    .map((distanceObj) => ({
                        distance: distanceObj.distance,
                        riskLevel: distanceObj.risk_level,
                        type: distanceObj.type,
                        ellipsePoints2d: distanceObj.ellipse_points_2d,
                        closestPoint2d: distanceObj.closest_point_2d,
                    }));

                const updatedData = {
                    ...frameData,
                    distanceData: distanceObjs,
                };

                detectionFrameMap.set(frameNum, updatedData);
            });

            // Extracting data for safe violations
            const safeRoisObj = rois.find((roi) => roi.roi_type === 5); // FIX-- 5 should be changed into a constant global variable
            if (safeRoisObj) {
                violationData.forEach((violationObj) => {
                    const { is_safe: isSafe = true, violation_start_frame: violationStartFrame = null } = violationObj;

                    if (!isSafe || !violationStartFrame) {
                        return;
                    }

                    const frameData = detectionFrameMap.get(violationStartFrame) || { };

                    detectionFrameMap.set(violationStartFrame, {
                        ...frameData,
                        isSafe: true,
                    });
                });
            }

            let speedLimit = 0;
            if (camera && camera.hsafety_setting && camera.hsafety_setting.temporary_speed_limit) {
                speedLimit = camera.hsafety_setting.temporary_speed_limit;
            }
            const mappedDetectionData = [
                ...video.detection_data,
                ...video.speed_data
                    .filter((data) => data.speed > speedLimit)
                    .map((data) => ({
                        rois: [],
                        tags: [
                            ...(data.track.plate && data.track.plate !== 'None' ? [data.track.plate] : []),
                            'Overspeed',
                        ],
                        conf_average: data?.track?.max_confidence ? Math.min(data.track.max_confidence * 100, 100)
                            : null,
                        object_class: data.object_class || 'Car',
                    })),
            ];

            // Extracting annotations
            const { annotation_data: annotationTracks = { } } = video;

            // Extracking frames with annotations for timeline data
            if (annotationTracks) {
                const annotationFrameSet = new Set();
                Object.values(annotationTracks).forEach((annotationTrack) => {
                    const { frames } = annotationTrack;

                    if (frames) {
                        frames.forEach((frameNum) => annotationFrameSet.add(frameNum));
                    }
                });

                annotationFrameSet.forEach((frameNum) => {
                    const frameData = detectionFrameMap.get(frameNum) || { };

                    detectionFrameMap.set(frameNum, {
                        ...frameData,
                        isAnnotated: true,
                    });
                });
            }
            return {
                isVideo: true,
                poster: video.poster_image,
                videoFile: video.display_video_file,
                videoFileOrig: video.ori_video_file,
                rowType: row?.rowType || '',
                videoDetections: mappedDetectionData,
                videoDetectionsTracked: mappedTrackData,
                proximityTracks: violatorTrack,
                timeCreated: video.time_created || null,
                videoId: video.id,
                imageId: image?.id ?? null,
                camera: { id: video?.camera || null, ...camera },
                detectionFrameSumMap: detectionFrameMap,
                annotationTracks,
            };
        },

        imageMapper({ image, row = null }) {
            return {
                plates: row?.platesList || [],
                confidences: row?.confidences || null,
                image: image.image_file,
                imageId: image.id,
                detections: image.detected_objects,
                camera: get(row, 'camera', { id: image?.camera || null }),
                timeCreated: image.time_created || null,
                rowType: row?.rowType || '',
                tags: (row?.detectionTags || []),
                detectionPlate: row?.plate || null,
            };
        },

        viewDetailByMediaId(mediaId) {
            Promise.all([this.loadVideoDataRow({ id: mediaId }), this.loadDataRow({ id: mediaId })]).then(
                async (result) => {
                    const [video, image] = result;

                    if (video) {
                        const camera = await this.loadCameraData(video.camera);
                        this.mediaData = this.videoMapper({ video, camera, imageId: null });
                        this.showCarJamInfo = true;
                        return;
                    }

                    if (image) {
                        this.mediaData = this.imageMapper({ image });
                        this.showCarJamInfo = true;
                    }
                },
            );
        },

        viewDetailsById(row, index) {
            if (this.isRowLoading) {
                return;
            }

            this.isRowLoading = true;

            const getImageId = (row) => {
                if (row.isImage) {
                    return row.id;
                }
                if (isString(row.image)) {
                    return row.image;
                }
                if (isPlainObj(row.image)) {
                    return row.image.id;
                }
                if (isString(row.image_id)) {
                    return row.image_id;
                }
                return null;
            };

            this.lastSelectedRow = row;
            this.lastSelectedRowIndex = index;

            const imageId = getImageId(row);
            const videoId = row.video;

            if (videoId) {
                Promise.all([
                    this.loadVideoDataRow({ id: videoId }),
                    this.loadDataRow({ id: imageId }),
                    this.loadCameraData(row.camera.id),
                ]).then((result) => {
                    const [video, image, camera] = result;
                    this.platesAndImageAndWithDetail = this.videoMapper({ video, camera, image, row });
                    this.showCarJamInfo = true;
                    this.isRowLoading = false;
                });
                return;
            }

            this.loadDataRow({ id: imageId }).then((image) => {
                this.platesAndImageAndWithDetail = this.imageMapper({ image, row });
                this.showCarJamInfo = true;
                this.isRowLoading = false;
            });
        },

        viewDetails(row, index) {
            this.viewDetailsById(row, index);
        },

        closePopup() {
            this.platesAndImageAndWithDetail = getPlatesAndImageAndWithDetailDefaults();
            this.showCarJamInfo = false;
        },
    },
};
