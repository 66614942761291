<template>
    <div class="detection-time">
        <BeCopy :copy-text="timeFormatted" :show-copy-label="false" />
    </div>
</template>

<script>
import BeCopy from '@/components/global/BeCopy';

export default {
    components: {
        BeCopy,
    },

    props: {
        time: {
            type: String,
            required: true,
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD HH:mm:ss',
        },
    },

    computed: {
        timeFormatted() {
            return this.time
                ? this.$date(this.time).format(this.format)
                : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.detection-time {
    white-space: nowrap;
    font-family: courier;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    width: 130px;
    justify-content: center;
    color: $white;
    background: $grey;

    &.has-no-time {
        width: 88px;
    }
}
</style>
