<template>
    <b-modal
        :active="active"
        :has-modal-card="true"
        :trap-focus="true"
        scroll="keep"
        @close="closePopup"
        width="100%"
        :can-cancel="['escape', 'outside']"
        :destroy-on-hide="true"
        class="media-image-popup"
    >
        <div class="modal-card" :class="{ 'is-loaded': imageWithDetail.isLoaded }">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    <div class="media-image-popup__toolbox_wrap">
                        <div class="media-image-popup__toolbox">
                            <div class="media-image-popup__heading">
                                <div class="media-image-popup__heading__left">
                                    <span class="media-image-popup__heading__left__camera">
                                        <template v-if="cameraName">
                                            <b-icon icon="camera" />
                                            <b-tooltip type="is-light" position="is-bottom" :triggers="['hover']" multilined size="is-medium">
                                                <template v-slot:content>
                                                    <div class="camera-info" style="z-index: 900;">
                                                        <div
                                                            style="width: 100%; display:flex; justify-content: space-between;">
                                                            <p>Focal length:</p>
                                                            <p>{{ imageWithDetail.camera.focal_length }} mm</p>
                                                        </div>
                                                        <div
                                                            style="width: 100%; display:flex; justify-content: space-between;">
                                                            <p>Vertical FOV:</p>
                                                            <p>{{ imageWithDetail.camera.vertical_fov }} deg</p>
                                                        </div>
                                                        <div
                                                            style="width: 100%; display:flex; justify-content: space-between;">
                                                            <p>Horizontal FOV:</p>
                                                            <p>{{ imageWithDetail.camera.horizontal_fov }} deg</p>
                                                        </div>
                                                    </div>
                                                </template>
                                                <span style="font-family: courier; white-space: nowrap; display: inline-flex;">
                                                    <BeCopy :copy-text="cameraName" :show-copy-label="false" @click.stop />
                                                </span>
                                            </b-tooltip>
                                        </template>
                                        <b-icon icon="image" />
                                        <span class="media-image-popup__media_id">
                                            <BeCopy
                                                :copy-text="imageWithDetail.isVideo ? imageWithDetail.videoId : imageWithDetail.imageId"
                                                :show-copy-label="false" />
                                            <template v-if="!imageWithDetail.isVideo">
                                                <BeDownload :image="imageWithDetail.image" />
                                                <BeCopyImage :image-src="imageWithDetail.image" />
                                            </template>
                                        </span>
                                    </span>
                                    <div class="tag-div">
                                        <b-icon icon="calendar-clock" />
                                        <be-detections-list
                                            class="tags"
                                            :clickable="false"
                                            :detections="detectionsListFormatted"
                                            :time="imageWithDetail.timeCreated || ''"
                                            :extra="0"
                                            wrap
                                            :is-media-image-popup="isMediaImagePopup"
                                            :display-tags="displayTags"
                                            :row-type="rowType"
                                            :display-confidence-label="false"
                                            :display-count="false"
                                            :has-prediction-confirmation="hasPredictionConfirmation && !imageWithDetail.isVideo"
                                            @click-prediction="onPredictionChange"
                                            @close-prediction="onPredictionChange"
                                        />
                                    </div>
                                </div>

                                <div class="media-image-popup__heading__right">
                                    <div class="media-image-popup__options">
                                        <b-button @click="toggleHeatmap('depth')" class="modal-card-close" :class="{ 'is-active': selectedHeatmap === 'depth' }">
                                            <b-icon is-size="is-medium" icon="layers-triple" />
                                        </b-button>
                                        <b-button @click="toggleHeatmap('3d')" class="modal-card-close" :class="{ 'is-active': selectedHeatmap === '3d' }">
                                            <b-icon is-size="is-medium" icon="rotate-3d" />
                                        </b-button>
                                    </div>

                                    <b-dropdown style="z-index: 900;">
                                        <template #trigger>
                                            <b-button class="modal-card-close">
                                                <b-icon icon="cog" />
                                            </b-button>
                                        </template>

                                        <b-dropdown-item custom>
                                            <b-checkbox :disabled="!displayTags" v-model="displayOptions.showTrails">
                                                Show Trails
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item custom>
                                            <b-checkbox :disabled="!displayTags" v-model="displayOptions.showSpeed">
                                                Show Speed
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item custom>
                                            <b-checkbox :disabled="!displayTags" v-model="displayOptions.showTest">
                                                Show Proximity
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item custom>
                                            <b-checkbox :disabled="!displayTags" v-model="displayOptions.showPPECheck">
                                                Show PPE
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item custom>
                                            <b-checkbox v-model="displayOptions.showBb">
                                                Bounding boxes
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item custom>
                                            <b-checkbox v-if="isHS" v-model="displayRoi">
                                                Display Zones
                                            </b-checkbox>
                                        </b-dropdown-item>

                                        <b-dropdown-item v-if="canTogglePrivacy" custom>
                                            <b-checkbox :disabled="!displayTags" v-model="displayOptions.showPrivacy">
                                                Show Privacy
                                            </b-checkbox>
                                        </b-dropdown-item>
                                    </b-dropdown>

                                    <div class="media-image-popup__tags__confirmation">
                                        <be-prediction-confirmation
                                            style="display: flex; align-items: center; justify-items: center; padding: 0 10px;"
                                            :detection-id="imageWithDetail.isVideo ? imageWithDetail.videoId : imageWithDetail.imageId"
                                            :detection-type="imageWithDetail.isVideo ? 'video' : 'image'" />
                                    </div>
                                </div>

                            </div>
                            <div class="media-image-popup__tags">
                                <button type="button" class="modal-card-close" @click.prevent="closePopup">
                                    <b-icon icon="close" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="modal-card-body">
                <media-3d v-if="display3D" v-bind="media3dBindings" />
                <media-video
                    v-else-if="imageWithDetail.isVideo"
                    v-bind="videoBindings"
                    :showProximity="displayOptions.showTest"
                    :showSpeed="displayOptions.showSpeed"
                    :showPPECheck="displayOptions.showPPECheck"
                    :showTrail="displayOptions.showTrails"
                    :showPrivacy="displayOptions.showPrivacy"
                    :showBb="displayOptions.showBb"
                />
                <media-image v-else v-bind="imageBindings" />
                <template v-if="displayArrows">
                    <b-button class="modal-arrow is-left" icon-left="chevron-left" @click="gotoPrev()" type="is-dark" />
                    <b-button class="modal-arrow is-right" icon-left="chevron-right" @click="gotoNext()" type="is-dark" />
                </template>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { get, isArray, isEmpty, kebabCase } from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { makeId } from '@/utils/generators';
import MediaImage from '@/components/local/data/MediaImage';
import Media3d from '@/components/local/data/Media3D';
import MediaVideo from '@/components/local/data/MediaVideo';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import BeCopyImage from '@/components/global/BeCopyImage';
import BeCopy from '@/components/global/BeCopy';
import BeDownload from '@/components/global/BeDownload';
import { getApiUrl } from '@/utils/api';
import BePredictionConfirmation from '@/components/global/BePredictionConfirmation';
import store from '@/store/store';

export default {
    components: {
        MediaImage,
        MediaVideo,
        BeDetectionsList,
        BeCopyImage,
        BeCopy,
        BeDownload,
        BePredictionConfirmation,
        Media3d,
    },

    props: {
        active: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        imageWithDetail: {
            type: Object,
            required: true,
        },
        displayArrows: {
            type: Boolean,
            default: false,
        },
        hasPredictionConfirmation: {
            type: Boolean,
            default: true,
        },
        imageType: {
            type: String,
            default: 'vehicle',
        },
    },

    data() {
        return {
            predictionDetection: null,
            displayTags: true,
            displayRoi: true,
            displayHeatmap: false,
            selectedHeatmap: null,
            displayOrientation: false,
            heatmapImages: {
                default: null,
                day: null,
                week: null,
                month: null,
                depth: null,
            },
            depthMapUrl: null,
            displayOptions: {
                showSpeed: true,
                showPPECheck: false,
                showTrails: true,
                showProximity: true,
                showTest: true,
                showPrivacy: true,
                showBb: true,
            },
            cameraObj: null,
        };
    },

    computed: {
        ...mapGetters('user', ['userDetails']),

        display3D() {
            return this.selectedHeatmap === '3d';
        },

        canTogglePrivacy() {
            return this.userDetails?.role === 'ADMIN';
        },

        isMediaImagePopup() {
            return true;
        },
        rowType() {
            return this.imageWithDetail.rowType || '';
        },
        isHS() {
            return (this.rowType || 'hs') === 'hs';
        },
        imageBindings() {
            return {
                url: this.imageWithDetail.image,
                imageId: this.imageWithDetail.imageId,
                camera: this.imageWithDetail.camera,
                detections: this.displayDetections,
                loading: this.loading,
                imageType: this.imageType,
                active: this.active,
                rowType: this.rowType,
                displayRoi: this.displayRoi,
                displayTag: this.displayTags,
            };
        },

        media3dBindings() {
            return {
                imageWithDetail: this.imageWithDetail,
                depthMapUrl: this.depthMapUrl,
                imageId: this.imageWithDetail.imageId,
                camera: this.imageWithDetail.camera,
                detections: this.displayDetections,
                loading: this.loading,
                imageType: this.imageType,
                active: this.active,
                rowType: this.rowType,
                displayRoi: this.displayRoi,
                displayTag: this.displayTags,
            };
        },

        videoBindings() {
            const tracks = get(this.imageWithDetail, 'videoDetectionsTracked', {});
            // .map((track) => this.removeErroneousBbs(
            //     this.markErroneousBbs(
            //         track,
            //         this.calculateAverageBb(track),
            //     ),
            // ));
            return {
                url: this.imageWithDetail.videoFile || this.imageWithDetail.videoFileOrig,
                poster: this.imageWithDetail.poster,
                loading: this.loading,
                active: this.active,
                camera: this.imageWithDetail.camera,
                tracks,
                proximityTracks: this.imageWithDetail.proximityTracks,
                detections: this.displayDetections,
                displayRoi: this.displayRoi,
                displayHeatmap: this.displayHeatmap,
                displayTags: this.displayTags,
                heatmapImage: this.heatmapImages[this.selectedHeatmap] || null,
                detectionFrameDataMap: this.imageWithDetail.detectionFrameSumMap,
                videoId: this.imageWithDetail.videoId,
                videoAnnotationTracks: this.imageWithDetail.annotationTracks,
            };
        },

        detections() {
            return get(this.imageWithDetail, 'detections', {});
        },

        detectionsTags() {
            // for video we need to transform the tags into the detections list
            if (this.imageWithDetail.isVideo) {
                const tags = this.videoDetections.reduce((curr, item) => (
                    curr.concat(item.tags || [])
                ), []);
                return tags;
            }

            const resultsGeneric = get(this.allDetections, 'results_generic', []);
            const tags = resultsGeneric.reduce((curr, item) => (
                curr.concat(item.tags || [])
            ), []);

            if (!this.isHS) {
                const results = get(this.imageWithDetail, 'tags', []);
                results.forEach((result) => {
                    if (!tags.includes(result)) {
                        tags.push(result);
                    }
                });
            }

            return tags;
        },

        videoDetections() {
            const detections = get(this.imageWithDetail, 'videoDetections', []);
            // ensure we have an array first because some of the data in the testing env is invalid
            return isArray(detections) ? detections : [];
        },

        allDetections() {
            const detections = {
                ...this.imageWithDetail.detections,
            };

            if (!isEmpty(detections.results)) {
                detections.results = detections.results.map((result) => (
                    this.generateResultWithId(result)
                ));
            }

            if (!isEmpty(detections.results_generic)) {
                detections.results_generic = detections.results_generic.map((result) => (
                    this.generateResultWithId(result)
                ));
            }

            if (!isEmpty(detections.results_other)) {
                Object.keys(detections.results_other).forEach((resultKey) => {
                    detections.results_other[resultKey] = detections.results_other[resultKey].map((result) => (
                        this.generateResultWithId(result)
                    ));
                });
            }

            return detections;
        },

        displayDetections() {
            if (this.predictionDetection) {
                const detections = {
                    ...this.allDetections,
                };

                if (!isEmpty(detections.results)) {
                    detections.results = detections.results.filter((result) => (
                        result.id === this.predictionDetection.id
                    ));
                }

                if (!isEmpty(detections.results_generic)) {
                    detections.results_generic = detections.results_generic.filter((result) => (
                        result.id === this.predictionDetection.id
                    ));
                }

                if (!isEmpty(detections.results_other)) {
                    const resultsOther = {
                        ...detections.results_other,
                    };
                    Object.keys(resultsOther).forEach((resultKey) => {
                        resultsOther[resultKey] = resultsOther[resultKey].filter((result) => (
                            result.id === this.predictionDetection.id
                        ));
                    });
                    detections.results_other = resultsOther;
                }

                return detections;
            }
            return this.allDetections;
        },

        detectionsList() {
            if (!this.allDetections
                || !this.allDetections.results) {
                return null;
            }

            let results = [
                ...this.allDetections.results,
            ];

            if (!isEmpty(this.allDetections.results_generic)) {
                results = results.concat(this.allDetections.results_generic);
            }

            if (this.allDetections.results_other) {
                Object.keys(this.allDetections.results_other).forEach((key) => {
                    if (isArray(this.allDetections.results_other[key])) {
                        let detection = '';
                        switch (key) {
                            case 'people':
                                detection = 'person';
                                break;
                            case 'bicycles':
                                detection = 'bicycle';
                                break;
                            default:
                                detection = '';
                                break;
                        }

                        this.allDetections.results_other[key].forEach((row) => {
                            results.push({
                                id: row.id,
                                type: detection,
                                plate: detection,
                                confidence: row.confidence,
                            });
                        });
                    }
                });
            }

            return results;
        },

        detectionsListFormatted() {
            // for video we need to transform the tags into the detections list
            if (this.imageWithDetail.isVideo) {
                return this.videoDetections
                    .map((item) => {
                        const detection = kebabCase(item.object_class);
                        return {
                            detection,
                            type: detection,
                            confidence: item.conf_average,
                            tags: item.tags.includes('Hat', 'Vis', 'Stand')
                                ? item.tags.filter((t) => t !== 'Hat' && t !== 'Vis' && t !== 'Stand')
                                : item.tags
                                    || [],
                        };
                    }).filter((item) => {
                        switch (item.type) {
                            case 'HIGHVIZ':
                            case 'FORKLIFT':
                            case 'PERSON':
                                return false;
                            default:
                                return true;
                        }
                    });
            }

            if (!this.detectionsList) {
                return [];
            }

            return this.detectionsList.map((result) => {
                const type = result.type || result.object_class;
                const detection = this.imageType === 'dolphin'
                    ? result.type
                    : (result.plate || result.object_class);
                return {
                    // we also want to give it a custom index to identify when the prediction was clicked
                    id: result.id,
                    confidence: result.confidence,
                    type,
                    detection,
                    tags: [
                        ...(result?.tags || []),
                        ...(this.imageWithDetail.detectionPlate === result.plate ? this.imageWithDetail.tags : []),
                    ],
                    orientation: result.vehicle_orientation || null,
                    vehicle_model: result.vehicle_makemodel || null,
                    vehicle_model_confidence: result.vehicle_makemodel_confidence || null,
                };
            }).filter((r) => r);
        },

        cameraName() {
            return this.imageWithDetail?.camera?.name || null;
        },
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    // this.$root.$emit('intercom:hide');
                }
            },
            immediate: true,
        },
        detectionsTags: {
            handler(val) {
                if (val && val.length && this.displayTags === null) {
                    this.displayTags = true;
                }
            },
            immediate: true,
        },
        imageWithDetail: {
            handler(val) {
                if (val) {
                    const { camera } = val;

                    this.displayHeatmap = false;
                    if (this.imageWithDetail && this.imageWithDetail.videoId) {
                        this.fetchHeatmapImage();
                    }

                    if (val.isVideo) {
                        window.removeEventListener('keyup', this.keyupHandler);
                    }

                    this.cameraObj = camera;
                }
            },
            immediate: true,
        },
        selectedHeatmap: {
            handler(val) {
                if (val) {
                    this.displayHeatmap = true;
                } else {
                    this.displayHeatmap = false;
                }
            },
            immediate: true,
        },
    },

    mounted() {
        window.addEventListener('keyup', this.keyupHandler);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('keyup', this.keyupHandler);
        });
    },

    methods: {
        keyupHandler(ev) {
            if (ev.keyCode === 37) {
                this.gotoPrev();
                return;
            }
            if (ev.keyCode === 39) {
                this.gotoNext();
            }
        },

        gotoPrev() {
            this.$emit('prev');
        },
        gotoNext() {
            this.$emit('next');
        },
        closePopup() {
            this.$emit('close');
            this.$emit('update:active', false);
            // this.$root.$emit('intercom:show');
        },

        onPredictionChange(detection) {
            if (detection) {
                this.predictionDetection = {
                    ...detection,
                };
                return;
            }
            this.predictionDetection = null;
        },

        generateResultWithId(result) {
            return {
                ...result,
                id: makeId(5),
            };
        },

        async fetchHeatmapImage() {
            const url = getApiUrl({
                path: `hsafety/heatmap/${this.imageWithDetail.videoId}/${this.imageWithDetail.camera.id}/`,
            });
            const depthMapUrl = getApiUrl({
                path: `hsafety/depth-map/${this.imageWithDetail.videoId}/`,
            });

            // Fetch both regular heatmap and depth map
            const [heatmapResponse, depthMapResponse] = await Promise.allSettled([
                axios.get(url),
                axios.get(depthMapUrl),
            ]);

            const heatmapUrls = {
                default: heatmapResponse.status === 'fulfilled' ? heatmapResponse.value.data?.image_url : null,
                day: heatmapResponse.status === 'fulfilled' ? heatmapResponse.value.data?.image_day_url : null,
                week: heatmapResponse.status === 'fulfilled' ? heatmapResponse.value.data?.image_week_url : null,
                month: heatmapResponse.status === 'fulfilled' ? heatmapResponse.value.data?.image_month_url : null,
                depth: depthMapResponse.status === 'fulfilled' ? depthMapResponse.value.data?.url : null,
            };

            Object.entries(heatmapUrls).forEach(([key, value]) => {
                if (value) {
                    const image = new window.Image();
                    image.src = value;
                    image.onload = () => {
                        this.heatmapImages[key] = image;
                    };
                }
            });

            const depthMapRawUrl = getApiUrl({
                path: `hsafety/depth-map/${this.imageWithDetail.videoId}/?raw=true`,
            });

            const { token } = store.state.auth;
            const depthMapRawResponse = await fetch(depthMapRawUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (depthMapRawResponse.status !== 404) {
                const depthMapRawResponseJson = await depthMapRawResponse.json();
                this.depthMapUrl = depthMapRawResponseJson.url;
            }
        },

        calculateAverageBb(tracks) {
            const windowSize = 3;
            const sumBb = {
                b: 0,
                l: 0,
                r: 0,
                t: 0,
            };

            let count = 0;

            for (let i = 0; i < tracks.length; i++) {
                if (tracks[i].bb) {
                    const { bb } = tracks[i];
                    sumBb.b += bb.b;
                    sumBb.l += bb.l;
                    sumBb.r += bb.r;
                    sumBb.t += bb.t;
                    count++;

                    if (i >= windowSize) {
                        const { bb } = tracks[i - windowSize];
                        sumBb.b -= bb.b;
                        sumBb.l -= bb.l;
                        sumBb.r -= bb.r;
                        sumBb.t -= bb.t;
                        count--;
                    }
                }
            }

            return {
                b: Math.floor(sumBb.b / count) || 0,
                l: Math.floor(sumBb.l / count) || 0,
                r: Math.floor(sumBb.r / count) || 0,
                t: Math.floor(sumBb.t / count) || 0,
            };
        },

        calculateBbIqr(tracks) {
            const sortedBbs = tracks
                .filter((track) => track.bb)
                .map((track) => track.bb)
                .sort((a, b) => a.b * a.t * a.r * a.l - b.b * b.t * b.r * b.l);

            const q1Index = Math.floor(sortedBbs.length / 4);
            const q3Index = Math.floor(sortedBbs.length * (3 / 4));

            const q1 = sortedBbs[q1Index];
            const q3 = sortedBbs[q3Index];

            return [q1, q3];
        },

        isBbErroneous(bb, averageBb) {
            const positionThreshold = 20;

            const positionDiff = Math.abs(bb.b - bb.t - (averageBb.b - averageBb.t))
                + Math.abs(bb.l - bb.r - (averageBb.l - averageBb.r));

            return (
                positionDiff <= positionThreshold
            );
        },

        markErroneousBbs(tracks, averageBb) {
            const markedTracks = [...tracks];
            tracks.forEach((track, index) => {
                if (this.isBbErroneous(track.bb, averageBb)) {
                    markedTracks[index].erroneous = true;
                } else {
                    markedTracks[index].erroneous = false;
                }
            });

            return markedTracks;
        },

        removeErroneousBbs(tracks) {
            const filteredTracks = [];

            for (let i = 0; i < tracks.length; i++) {
                if (!tracks[i].erroneous) {
                    filteredTracks.push(tracks[i]);
                }
            }

            return filteredTracks;
        },

        handleSelectOptions(option) {
            console.log(option);
        },

        toggleHeatmap(option) {
            if (this.selectedHeatmap === option) {
                console.log('un selected');
                this.selectedHeatmap = null;
            } else {
                console.log('selected');
                this.selectedHeatmap = option;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.media-image-popup {
    ::v-deep .animation-content {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        margin: 0 20px;

        @include mobile {
            width: 100%;
            height: 100%;
            margin: 0;
        }

    }

    &__media_id {
        margin-top: 5px;
        font-family: courier;
        white-space: nowrap;
        display: inline-flex;

        @include desktop {
            margin-top: 0;
        }
    }

    &__tags {
        font-size: 14px;
        white-space: nowrap;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding: 5px 15px;
        align-items: center;
        gap: 30px;

        &__confirmation {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &__checkboxes {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: flex-start;
        }

        .b-checkbox {
            padding: 1px 0;
        }
    }

    ::v-deep .heatmap-selection select {
        border: 1px solid $grey-darker;
        margin: 1px;
    }

    .modal-card {
        .modal-card-head {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .modal-card-title {
            flex: 1;
        }

        &__is-loaded {
            height: auto;
        }
    }

    &__options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }

    &__heading {
        z-index: 1000;
        height: 50px;
        overflow: visible;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        font-size: 15px;
        @include tablet {
            font-size: 16px;
        }
        @include desktop {
            font-size: 18px;
        }

        &__left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;
            align-items: center;

            &__camera {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 10px;
            align-items: center;
        }
    }

    &__toolbox_wrap {
        width: 100%;
    }

    &__toolbox {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__detections {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.modal-card {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: 100vh;

    .modal-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
    }

    .modal-card-body {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            padding: 12px;
        }
    }

    .modal-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include z-index(above);
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        opacity: .6;
        transition: opacity $speed $easing;

        @include mobile {
            transform: none;
            bottom: 25px;
            top: auto;
        }

        &.is-left {
            left: 1.5rem;
        }

        &.is-right {
            right: 1.5rem;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.is-hidden-desktop {
    @media screen and (min-width: tablet) {
        display: none !important;
    }
}

.camera-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 10px;
}

.display-options {
    display: flex;
    align-items: center;
}

.modal-card-close {
    padding: 20px;
    &.is-active {
        background-color: $grey-darker;
    }
}

.tag-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    height: 50px;
    overflow: hidden;

    @media screen and (max-width: 1500px) {
        display: none;
    }

    ::v-deep .tags {
        margin: 0;
    }
}
</style>
