<template>
    <be-widget :contentClasses="['mapbox-map', 'sites-map']" :is-loading="isLoading">
        <template v-slot:heading>
            Geographic Distribution
        </template>
        <template v-slot:headingCenter>
            <div class="dist__row" v-if="hasSites">
                <button type="button" @click="() => onLegendClicked('high')" ref="legend-high" class="dist__row">
                    <div class="dist__row_icon">
                        <div class="dist__icon is-danger">
                            <b-icon :icon="`blank`" />
                        </div>
                        <div class="label-sub-text">Elevated</div>
                    </div>
                </button>
                <button type="button" @click="() => onLegendClicked('medium')" ref="legend-medium" class="dist__row">
                    <div class="dist__row_icon">
                        <div class="dist__icon is-warning">
                            <b-icon :icon="`blank`" />
                        </div>
                        <div class="label-sub-text">Moderate</div>
                    </div>
                </button>
            </div>
            <div v-else>
                <div class="mapbox-map sites-map__no-data">
                    <div class="mapbox-map__no-data_inner" v-if="!isLoading">
                        <div class="mapbox-map__no-data_text">
                            No sites available
                            <b-tooltip
                                type="is-dark"
                                position="is-right"
                                multilined
                            >
                                <template v-slot:content>
                                    You may need to configure geographical data for your cameras to see them on the map.
                                </template>
                                <b-icon
                                    icon="help-circle-outline"
                                    size="is-small"
                                    class="has-text-grey-lighter ml-2"
                                />
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:headingAfter>
            <div class="site-selector" v-if="hasSites">
                <b-select v-model="selectedSite" placeholder="Select a site" class="is-site" expanded>
                    <option v-for="site in availableSites" :key="site.id" :value="site.id">
                        {{ site.name }}
                    </option>
                </b-select>
            </div>
        </template>
        <template v-slot:content>
            <div class="dist" v-if="hasSites">
                <div class="mapbox-map sites-map" v-if="showMap">
                    <mgl-map
                        v-if="displayMap"
                        class="mapbox-map__map sites-map__map"
                        ref="map"
                        v-bind="mapboxOptions"
                        :map-style="mapStyle"
                        @load="onMapLoaded"
                    >
                        <template v-for="(marker, index) in camerasGeoJson">
                            <h-s-camera-marker
                                v-if="marker.properties.camera
                                    && (marker.properties.displayHigh || marker.properties.displayMedium) && marker.properties.hasData"
                                :key="`marker${index}`"
                                :item="marker.properties.camera"
                                :sizePercentage="marker.properties.sizePercentage"
                                :displayHigh="marker.properties.displayHigh"
                                :displayMedium="marker.properties.displayMedium"
                                :marker="marker"
                            />
                        </template>

                        <mgl-navigation-control position="bottom-right" :showZoom="false" />
                    </mgl-map>
                </div>
                <div v-else>
                    <div class="mapbox-map sites-map__no-data">
                        <div class="mapbox-map__no-data_inner">
                            <div class="mapbox-map__no-data_text">Site has no cameras</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </be-widget>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglNavigationControl } from 'v-mapbox';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getMapStyle, MAPBOX_OPTIONS } from '@/utils/map';
import mapMixin from '@/mixins/mapMixin';
import HSCameraMarker from '@/components/local/sites/hs/HSCameraMarker';

export default {
    name: 'HSObservationsMap',

    mixins: [mapMixin],

    components: {
        MglNavigationControl,
        HSCameraMarker,
    },

    data() {
        return {
            shouldLoad: false,
            selectedSite: null,
            mapboxOptions: {
                ...MAPBOX_OPTIONS,
                center: [174.8300225, -36.9435007],
                attributionControl: false,
                dragPan: false,
                scrollZoom: false,
                boxZoom: false,
                doubleClickZoom: false,
                touchPitch: false,
                touchZoomRotate: false,
                maxZoom: 17.4,
                minZoom: 15,
            },
            displayHigh: true,
            displayMedium: true,
            displayMap: false,
        };
    },

    watch: {
        selectedSite(newValue) {
            this.displayMap = false;
            this.loadSiteData({ siteId: newValue });
        },
        getFilterHash() {
            this.onRefreshData();
        },
        countData: {
            handler() {
                this.handleDisplayMap();
            },
            deep: true,
        },
        mapBounds: {
            handler() {
                this.handleDisplayMap();
            },
            deep: true,
        },

        availableSites() {
            if (this.availableSites.length > 0) {
                this.shouldLoad = true;
                this.selectedSite = this.availableSites[0].id;
            }
        },

        camerasCoordinates() {
            this.handleDisplayMap();
        },

        camerasGeoJson: {
            handler() {
                // Setting center of map
                const [lng, lat] = Object.values(this.camerasCoordinates[0]);
                this.mapboxOptions.center = [lng, lat];
            },
            deep: true,
        },
    },

    mounted() {
        this.loadAvailableSites();
    },

    computed: {
        ...mapGetters('hs/riskLevelCamerasSummary', ['isLoading']),
        ...mapState('hs/riskLevelCamerasSummary', ['countData', 'camerasCoordinates', 'availableSites']),
        ...mapGetters('dataFilters', ['getFilterHash']),

        mapStyle() {
            return getMapStyle('satellite');
        },

        camerasGeoJson() {
            console.log(this.countData);
            let sortedCountData = this.countData
                .toSorted((a, b) => (b.medium + b.high) - (a.medium + a.high));
            let difference = sortedCountData[0].medium
                + sortedCountData[0].high
                - sortedCountData[sortedCountData.length - 1].medium
                - sortedCountData[sortedCountData.length - 1].high;

            if (this.displayHigh && !this.displayMedium) {
                sortedCountData = this.countData.toSorted((a, b) => b.high - a.high);
                difference = sortedCountData[0].high - sortedCountData[sortedCountData.length - 1].high;
            } else if (!this.displayHigh && this.displayMedium) {
                sortedCountData = this.countData.toSorted((a, b) => b.medium - a.medium);
                difference = sortedCountData[0].medium - sortedCountData[sortedCountData.length - 1].medium;
            }

            return sortedCountData
                .map((camera) => {
                    let sizePercentage = ((camera.medium + camera.high)
                        - (sortedCountData[sortedCountData.length - 1].medium
                            + sortedCountData[sortedCountData.length - 1].high)) / difference;

                    if (this.displayHigh && !this.displayMedium) {
                        sizePercentage = (camera.high
                            - sortedCountData[sortedCountData.length - 1].high) / difference;
                    } else if (!this.displayHigh && this.displayMedium) {
                        sizePercentage = (camera.medium
                            - sortedCountData[sortedCountData.length - 1].medium) / difference;
                    }

                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: this.getCameraBounds(camera.metadata),
                        },
                        properties: {
                            // cluster: true,
                            camera,
                            sizePercentage,
                            displayHigh: this.displayHigh,
                            displayMedium: this.displayMedium,
                            hasData: camera.medium + camera.high > 0,
                        },
                    };
                });
        },

        showMap() {
            return this.camerasCoordinates.length > 0;
        },

        hasSites() {
            return this.availableSites.length > 0;
        },
    },

    methods: {
        ...mapActions('hs/riskLevelCamerasSummary', ['loadAvailableSites', 'loadSiteData']),

        onRefreshData() {
            this.displayMap = false;
            this.loadSiteData({ siteId: this.selectedSite });
        },

        onMapLoaded() {
            this.setMapBounds();
        },

        getMapBounds() {
            if (!Object.entries(this.camerasCoordinates).length) {
                return null;
            }

            return Object.values(this.camerasCoordinates).reduce((bnds, camera) => {
                bnds.extend(this.getCameraBounds(camera));
                return bnds;
            }, new Mapbox.LngLatBounds());
        },

        setMapBounds() {
            const bounds = this.getMapBounds();
            if (bounds) {
                this.$refs.map.map.fitBounds(bounds, {
                    duration: 0,
                });

                this.$refs.map.map.setMaxBounds(bounds);
            }
        },

        getCameraBounds(camera) {
            return [
                camera.lng,
                camera.lat,
            ];
        },

        onLegendClicked(category) {
            if (this.$refs[`legend-${category}`].classList.contains('is-removed')) {
                this.$refs[`legend-${category}`].classList.remove('is-removed');
            } else {
                this.$refs[`legend-${category}`].classList.add('is-removed');
            }

            if (category === 'high') {
                this.displayHigh = !this.displayHigh;
            } else if (category === 'medium') {
                this.displayMedium = !this.displayMedium;
            }
        },

        handleDisplayMap() {
            // If already displaying map, do nothing
            if (this.displayMap) {
                return;
            }

            if (Object.entries(this.camerasCoordinates).length === 0 || this.countData.length === 0) {
                return;
            }

            // Grabbing center of map
            const { lat, lng } = Object.values(this.camerasCoordinates)[0];

            if (lat === null || lng === null || lat === undefined || lng === undefined) {
                return;
            }

            // Validating lat and lng values
            if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
                return;
            }

            // Setting map center and zoom
            this.mapboxOptions.center = [lng, lat];
            this.mapboxOptions.zoom = 17; // Todo: Update this to be dynamic

            this.displayMap = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import url('https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css');

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.dist__heading {
    flex-grow: 0;
    align-items: center;
    justify-content: space-between;
}

.dummy {
    flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
}

.is-removed {
    text-decoration: line-through;
}

.sites-map {
    height: 50vh;
    width: 100%;
}

.dist {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &__inner {
        padding: 10px;
        @include mobile {
            padding: 5px;
        }
    }

    &__row_icon {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &__row {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &__col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    &__footer {
        @include tablet {
            display: flex;
            align-items: stretch;
        }

        &__cta {
            @include tablet {
                flex: 1;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    &__icon {
        position: relative;
        margin: 10px 0;
        width: 23px;
        height: 23px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-rounded;

        &.is-success {
            background: $low;
            color: $low-invert;
        }

        &.is-warning {
            background: $moderate;
            color: $moderate-invert;
        }

        &.is-danger {
            background: $critical;
            color: $critical-invert;
        }

        &.is-critical {
            background: $critical;
            color: $critical-invert;
        }

        &.is-info {
            background: $info;
            color: $info-invert;
        }

        &.is-light {
            background: $light;
            color: $light-invert;
        }
    }
}

.site-selector {
    min-width: 200px;
}
</style>
