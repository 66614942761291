<template>
    <div class="prediction-confirmation">
        <b-tooltip type="is-light" position="is-bottom" :triggers="['hover']" multilined size="is-small">
            <template v-slot:content>
                <p>Mark video observation as <span class="success-text">correct</span></p>
            </template>
            <b-button
                @click="onClick(CONFIDENCE_TYPES.CORRECT)"
                class="be-button is-success is-smaller has-text-weight-bold"
            >
                <b-icon icon="check" />
            </b-button>
        </b-tooltip>
        <b-tooltip type="is-light" position="is-bottom" :triggers="['hover']" multilined size="is-small">
            <template v-slot:content>
                <p>Mark video observation as <span class="incorrect-text">incorrect</span></p>
            </template>
            <b-button
                @click="onClick(CONFIDENCE_TYPES.NO)"
                class="be-button is-danger is-smaller has-text-weight-bold no-button"
            >
                X
            </b-button>
        </b-tooltip>
        <div v-if="showCommentModal" class="modal-overlay">
            <div class="modal-content">
                <h2 class="modal-title">Please leave a comment</h2>
                <b-field :label="correctLabel">
                    <b-input
                        style="margin: 10px 0;"
                        v-model="remark"
                        placeholder="Enter your comment here..."
                        type="textarea"
                        rows="3"
                    />
                </b-field>
                <div class="modal-actions">
                    <b-button @click="submitRemark" type="is-primary">
                        Submit
                    </b-button>
                    <b-button @click="cancelRemark" type="is-dark">
                        Cancel
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import noticesMixin from '@/mixins/noticesMixin';
import { getApiUrl } from '@/utils/api';
import { CONFIDENCE_TYPES } from '@/utils/constants';

export default {
    mixins: [noticesMixin],

    data() {
        return {
            showCommentModal: false,
            remark: '',
            selectedType: null,
            CONFIDENCE_TYPES,
        };
    },

    props: {
        detectionType: {
            type: String,
            default: null,
        },
        detectionId: {
            type: String,
            default: null,
        },
    },

    methods: {
        onClick(type) {
            switch (type) {
                case CONFIDENCE_TYPES.NO:
                case CONFIDENCE_TYPES.MAYBE:
                case CONFIDENCE_TYPES.CORRECT:
                    this.selectedType = type;
                    this.showCommentModal = true;
                    break;
                default:
                    break;
            }
        },
        submitRemark() {
            if (this.remark.trim()) {
                const isSuccess = this.submit(this.selectedType, this.remark);

                if (isSuccess) {
                    this.resetModal();
                }
            } else {
                this.displayErrorNotice({
                    message: "The comment box can't be empty!",
                    position: 'is-top-right',
                });
            }
        },
        async submit(correctness = CONFIDENCE_TYPES.CORRECT, message = null) {
            const response = await axios.put(
                // TODO: Validate data? check for null? why would we send a null request if we know what it will fail?
                // TOOD: Abstrac out so we can use for many different app types
                getApiUrl({ path: 'hsafety/detection/feedback/' }),
                {
                    video_id: this.detectionType === 'video' ? this.detectionId : null,
                    detection_id: this.detectionId,
                    correctness,
                    user_remark: message,
                },
            );

            if (response.status !== 200) {
                this.displayErrorNotice({
                    message: 'Something went wrong. Please try again later.',
                    position: 'is-top-right',
                });
                return false;
            }

            this.displaySuccessNotice({
                message: 'Thank you for helping improve our accuracy!',
                position: 'is-top-right',
            });
            return true;
        },
        cancelRemark() {
            this.resetModal();
        },
        resetModal() {
            this.showCommentModal = false;
            this.remark = '';
            this.selectedType = null;
        },
    },

    computed: {
        correctLabel() {
            switch (this.selectedType) {
                case CONFIDENCE_TYPES.CORRECT:
                    return 'Why is this observation correct?';
                case CONFIDENCE_TYPES.NO:
                    return 'Why is this observation not correct?';
                case CONFIDENCE_TYPES.MAYBE:
                    return 'Why might this observation be correct or incorrect?';
                default:
                    return 'Leave a comment on the observation accuracy';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.prediction-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .button + .button {
        margin-left: .75rem;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #37404C;
    color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.no-button {
    font-size: 20px;
    font-weight: 400;
}

.success-text {
    font-weight: 600;
    color: #00C851;
}

.incorrect-text {
    font-weight: 600;
    color: #FF4444;
}

</style>
