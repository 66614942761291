import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

function editDistance(str1, str2) {
    const s1 = str1.toLowerCase();
    const s2 = str2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0) costs[j] = j;
            else if (j > 0) {
                let newValue = costs[j - 1];
                if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                    newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                }
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
        }
        if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

/*
Count Data object example:
{
    metadata: {
        id: '',
        name: '',
        lat: 0,
        lng: 0,
    },
    low: 0,
    medium: 0,
    high: 0,
}
*/

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: [],
    availableSites: [],
    camerasCoordinates: {
    },
    exclude: [
    ],
    isLoading: false,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },

    getCamerasCoordinates(state) {
        return state.camerasCoordinates;
    },

    isLoading(state) {
        return state.isLoading;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),

    // TODO: add lat and lng to cameras on the backend
    setCountData(state, payload) {
        state.countData = payload.filter((item) => !state.exclude.includes(item.camera))
            .map((item) => ({
                metadata: {
                    id: item.camera,
                    name: item.name,
                    lat: item.location_data.lat,
                    lng: item.location_data.lng,
                },
                low: item.low_risk,
                medium: item.medium_risk,
                high: item.high_risk,
            }));
    },

    setCamerasCoordinates(state, payload) {
        state.camerasCoordinates = payload.map(
            (item) => ({
                lat: item.location_data.lat,
                lng: item.location_data.lng,
            }),
        );
    },

    setAvailableSites(state, payload) {
        state.availableSites = payload;
    },

    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
};

const actions = {
    async loadAvailableSites({ commit }) {
        const path = 'hsafety/risk_score/available/sites/';

        try {
            commit('setIsLoading', true);
            const response = await axios({
                url: getApiUrl({ path }),
            });

            commit('setAvailableSites', response.data);
            commit('setIsLoading', false);
            return Promise.resolve(response.data);
        } catch (err) {
            commit('setIsLoading', false);
            return Promise.reject(err);
        }
    },

    async loadSiteData({ commit, rootState }, { siteId = null }) {
        commit('setIsLoading', true);

        if (!siteId) {
            commit('setIsLoading', false);
            return Promise.reject(new Error('Site ID is required'));
        }

        const { dataFilters } = rootState;
        const { startDate, endDate, selectedTags } = dataFilters;

        const selectedTagLabels = selectedTags.map((tag) => tag.label);

        const from = dayjs(startDate).format('YYYY-MM-DDTHH:mm:00');
        const to = dayjs(endDate).format('YYYY-MM-DDTHH:mm:00');

        const params = buildUrlParams({
            additional: {
                from,
                to,
            },
        });

        const path = `hsafety/risk_score/count/cameras/${siteId}/${from}/to/${to}/`;

        const dataHash = createDataHash(path, params, selectedTagLabels);

        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setDataHash', { dataHash });
        commit('setStatus', { status: 'loading' });
        commit('setIsLoading', true);

        try {
            const response = await axios({
                url: getApiUrl({ path }),
            });

            if (selectedTagLabels.length > 0) {
                const data = response.data.filter(
                    (item) => selectedTagLabels.some((label) => similarity(item.name, label) > 0.9),
                );
                commit('setCountData', data);
                commit('setCamerasCoordinates', data);
            } else {
                commit('setCountData', response.data);
                commit('setCamerasCoordinates', response.data);
            }

            commit('setStatus', { status: 'success' });
        } catch (e) {
            console.error('Error loading data:', e);
            commit('setStatus', { status: 'error' });
        }

        commit('setIsLoading', false);

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
